import Title from "../components/Title";
import FeaturedList from "../components/FeaturedList";

export default function FeaturedTours() {
  return (
    <div>
      <div className="bg-bgLight min-h-screen">
        <div className="relative isolate overflow-hidden pt-80 min-h-screen">
          <div className="mx-auto max-w-full mt-[-12rem]">
            <div className="text-center">
              <Title title={'Тематические туры'} />
              <p className="mx-auto max-w-2xl mt-6 text-16 leading-8 text-main">
                Вы можете вместе с нами отправиться в настоящее научное путешествие!<br/>
                Мы в реестре туроператоров: РТО 026198
              </p>
            </div>
            <FeaturedList />
          </div>
        </div>
      </div>
    </div>
  );
};
