import React from "react";
import {useNavigate, useParams} from "react-router-dom";
import {TourFull} from "../pages/Tour";


export default function TourProgram({tour}: {tour: TourFull}) {
  const navigate = useNavigate();
  const { tourId } = useParams();

  return (
    <div>
      <div className="my-5">
        <h2 className="text-36 text-accentBlue font-rubikSemi">Описание тура</h2>
        <p className="text-16 text-pretty">
          {tour.description}
        </p>
      </div>
      <div>
        <div
          dangerouslySetInnerHTML={{__html: tour.program?.replaceAll("className", "class")}}
        />
        <div
          dangerouslySetInnerHTML={{__html: tour.activities_in_payment?.replaceAll("className", "class")}}
        />
        <div className="my-5">
          <h3 className="text-24 text-accentBlue font-rubikSemi">Оплачивается отдельно</h3>
          <div
            dangerouslySetInnerHTML={{__html: tour.additional_payment?.replaceAll("className", "class")}}
          />
        </div>
      </div>
        <div className="mt-10">
        <button
          onClick={() => {
            navigate(`/book/${tourId}`);
          }}
          className="flex justify-center rounded-md bg-accentBlue px-[16px] py-[8px] text-16 leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
        >
          Забронировать
        </button>
      </div>
      <div className="mt-4">
        <button
          onClick={() => {
            navigate(`/tours/`);
          }}
          className="flex justify-center rounded-md bg-accentGreen px-[16px] py-[8px] text-16 leading-6 text-primaryBlack shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
        >
          Вернуться к списку туров
        </button>
      </div>
    </div>
  );
};
