export default function Motto() {
  return (
    <div className="bg-bgDark">
      <div className="py-10 flex flex-col sm:flex-row gap-y-10 items-center justify-evenly">
        <div className="flex flex-col justify-center items-center">
          <img className="h-16 sm:h-full" src={`${process.env.PUBLIC_URL}/number_1.svg`} alt=""/>
          <div className="text-white text-center text-16 sm:text-20 py-2 px-4 mx-auto">
            Выбирайте<br/>конкретный регион
          </div>
        </div>
        <div className="flex flex-col justify-center items-center">
          <img className="h-16 sm:h-full" src={`${process.env.PUBLIC_URL}/number_2.svg`} alt=""/>
          <div className="text-white text-center text-16 sm:text-20 py-2 px-4 mx-auto">
            Изучайте<br/>объекты туризма
          </div>
        </div>
        <div className="flex flex-col justify-center items-center">
          <img className="h-16 sm:h-full" src={`${process.env.PUBLIC_URL}/number_3.svg`} alt=""/>
          <div className="text-white text-center text-16 sm:text-20 py-2 px-4 mx-auto">
            Приобретайте<br/>научно-популярные туры
          </div>
        </div>
      </div>
      <h2 className="mx-auto pt-4 pb-12 font-rubikSemi text-center text-white text-24 sm:text-36">
        Путешествуйте и просвещайтесь!
      </h2>
    </div>
  );
};
