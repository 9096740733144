import {useNavigate} from "react-router-dom";
import {useState} from "react";
import {SubmitHandler, useForm} from "react-hook-form";
import {User, userRegister} from "../actions";
import Notification from "../components/Notification";

export default function Register() {
  const [agreed, setAgreed] = useState(false);
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
  } = useForm<User>()
  const onSubmit: SubmitHandler<User> = async (data) => {
    console.log('data: ', data);
    try {
      console.log(loading);
      setLoading(true);
      setError("");
      const result = await userRegister(data);
      setLoading(false);
      if (result.status === "success") {
        setMessage(result.message);
      }
    } catch (e) {
      console.log((e as Error).message);
      setError("Произошла непредвиденная ошибка! Попробуйте позже или свяжитесь с нами");
    }
  };

  return (
    <div className="bg-bgDark">
      <div className="h-full bg-bgDark min-h-screen mx-auto max-w-7xl py-5 px-4 sm:px-6 lg:px-8 ">
        <div className="flex min-h-full flex-1 flex-col justify-center items-start px-6 py-12 lg:px-8">
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <div onClick={() => navigate('/')} className="cursor-pointer -m-1.5 p-1.5">
              <span className="sr-only">SciTravel</span>
              <img
                className="mx-auto h-30 w-auto"
                src={`${process.env.PUBLIC_URL}/logo_white_icon.svg`}
                alt="SciTravel"
              />
            </div>
            <h2 className="mt-10 text-36 text-center font-rubikSemi text-white">
              Регистрация
            </h2>
          </div>
          <form className="mx-auto pb-12" onSubmit={handleSubmit(onSubmit)}>
            <div className="mx-auto w-full mt-10 grid grid-cols-1 place-self-center	 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="sm:col-span-3">
                <label htmlFor="last-name" className="block text-sm font-medium leading-6 text-white">
                  Фамилия
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    id="last-name"
                    autoComplete="family-name"
                    placeholder="Введите новый"
                    {...register("last_name", {
                      required: true,
                    })}
                    className="block w-full rounded-md border-0 px-[16px] py-[8px] text-primaryBlack placeholder-text-[#DBDBDB] shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div className="sm:col-span-3">
                <label htmlFor="given-name" className="block text-sm font-medium leading-6 text-white">
                  Имя
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    id="given-name"
                    autoComplete="given-name"
                    placeholder="Введите новый"
                    {...register("first_name", {
                      required: true,
                    })}
                    className="block w-full rounded-md border-0 px-[16px] py-[8px] text-primaryBlack placeholder-text-[#DBDBDB]shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              <div className="sm:col-span-3">
                <label htmlFor="phone" className="block text-sm font-medium leading-6 text-white">
                  Номер телефона
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    id="phone"
                    autoComplete="tel"
                    placeholder="Введите новый"
                    {...register("phone", {
                      required: true,
                    })}
                    className="block w-full rounded-md border-0 px-[16px] py-[8px] text-primaryBlack placeholder-text-[#DBDBDB] shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div className="sm:col-span-3">
                <label htmlFor="email" className="block text-sm font-medium leading-6 text-white">
                  E-mail
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    id="email"
                    autoComplete="email"
                    placeholder="Введите новый"
                    {...register("email", {
                      required: true,
                    })}
                    className="block w-full rounded-md border-0 px-[16px] py-[8px] text-primaryBlack placeholder-text-[#DBDBDB] shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div className="sm:col-span-3">
                <label htmlFor="password" className="block text-sm font-medium leading-6 text-white">
                  Пароль
                </label>
                <div className="mt-2">
                  <input
                    type="password"
                    id="password"
                    autoComplete="new-password"
                    placeholder="Введите пароль"
                    {...register("password", {
                      required: true,
                    })}
                    className="block w-full rounded-md border-0 px-[16px] py-[8px] text-primaryBlack placeholder-text-[#DBDBDB] shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div className="sm:col-span-3">
                <label htmlFor="password2" className="block text-sm font-medium leading-6 text-white">
                  Подтвердите пароль
                </label>
                <div className="mt-2">
                  <input
                    type="password"
                    id="password2"
                    autoComplete="new-password"
                    placeholder="Повторите пароль"
                    {...register("password2", {
                      required: true,
                    })}
                    className="block w-full rounded-md border-0 px-[16px] py-[8px] text-primaryBlack placeholder-text-[#DBDBDB] shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
            </div>
            <div>
              <div className="mt-6 max-w-3xl mx-auto flex items-center">
                <input
                  id="policy"
                  name="policy"
                  type="checkbox"
                  checked={agreed}
                  onChange={() => setAgreed(!agreed)}
                  className="h-5 w-5 rounded border-gray-300 text-accentBlue focus:ring-accentBlue"
                />
                <div className="ml-3">
                  <label className="text-12 text-white">
                    <span onClick={() => setAgreed(!agreed)} className="cursor-pointer">Я ознакомлен(а) с&nbsp;</span>
                    <a href={`${process.env.PUBLIC_URL}/documents/policy.pdf`}
                       className="underline"
                       target="_blank"
                       rel="noreferrer"
                    >
                      политикой&nbsp;конфиденциальности
                    </a>
                    <span onClick={() => setAgreed(!agreed)} className="cursor-pointer">&nbsp;и согласен(а) на&nbsp;</span>
                    <a
                      href={`${process.env.PUBLIC_URL}/documents/agreement.pdf`}
                      className="underline"
                      target="_blank"
                      rel="noreferrer"
                    >
                      обработку персональных данных
                    </a>
                  </label>
                </div>
              </div>
            </div>
            <div className="mt-10">
              <button
                type="submit"
                disabled={!agreed}
                className="flex justify-center rounded-md bg-accentBlue px-[16px] py-[8px] text-16 leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
              >
                Зарегистрироваться
              </button>
            </div>
          </form>
        </div>
      </div>
      <div className="pointer-events-none fixed top-0 right-0 w-80 px-4 py-6 z-50">
      {
        Boolean(error.length) ?
          <Notification text={error} setText={setError} type="error" /> : Boolean(message.length) ?
          <Notification text={message} setText={setMessage} type="message" /> : null
      }
      </div>
    </div>
  );
};
