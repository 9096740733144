import {useLocation, useNavigate} from "react-router-dom";
import TourCard from "../components/TourCard";
import React, {useEffect, useState} from "react";
import Notification from "../components/Notification";
import {useAuth} from "../auth_context";
import {getTours, logOut} from "../actions";

export type Profile = {
  email: string;
  phone: string;
  orders: any;
  tours: any;
  last_name: string;
  first_name: string;
};

export default function Account() {
  const navigate = useNavigate();
  const location = useLocation();
  const [message, setMessage] = useState(location.state?.message || "");
  const [error, setError] = useState(location.state?.error || "");
  const [tourMini, setTourMini] = useState<any>([]);
  const {user, isLogged, loading} = useAuth();

  useEffect(() => {
    const getToursMini = async () => {
      try {
        const result = await getTours()
        const bookedTours = Object.values(user.tours).map((tour: any) => tour.nominal_name);
        const orderIds = Object.values(user.orders).map((order: any) => order.tour_id);
        const toursToursMini = Object.values(result).filter((tourMini: any) => bookedTours.includes(tourMini.nominal_name) && orderIds.includes(tourMini.id));
        const orderTour = Object.values(user.orders).map((order: any) => {
          return {
            // @ts-ignore
            ...toursToursMini.filter((tour: any) => tour.id === order.tour_id).at(0),
            ...order,
          }
        })
        console.log(orderTour);
        setTourMini(orderTour);
      } catch (e) {
        console.log((e as Error).message);
      }
    }

    if (loading) {
      console.log("Loading auth context");
    } else if (isLogged) {
      getToursMini();
    } else {
      !loading && navigate('/login');
    }
  }, [isLogged, loading, navigate, user]);

  async function logout() {
    try {
      const result = await logOut(localStorage.getItem("access_token")!);
      if (result.status === "success") {
        navigate('/login', {state: {message: result.message}});
      }
    } catch (e) {
      console.log((e as Error).message);
    }
  }

  return (
    <div className="h-full bg-bgLight min-h-screen">
      <div className="flex min-h-full flex-1 flex-col justify-start items-start px-8 py-12 lg:px-32">
        <h2 className="mt-16 text-24 sm:text-36 text-center font-rubikSemi text-primaryBlack">
          Личный кабинет
        </h2>
        <div className="pb-12">
          <div className="w-full max-w-full mt-10 grid grid-cols-2 gap-x-12 gap-y-8 sm:grid-cols-9">
            <div className="col-span-2 sm:col-span-3">
              <label htmlFor="last-name" className="block text-sm font-medium leading-6 text-primaryBlack">
                Фамилия
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name="last-name"
                  id="last-name"
                  autoComplete="family-name"
                  placeholder="Введите текст"
                  disabled={true}
                  value={user.last_name}
                  className="block w-full rounded-md border-0 px-[16px] py-[8px] text-primaryBlack placeholder-text-[#DBDBDB] shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div className="col-span-2 sm:col-span-3">
              <label htmlFor="given-name" className="block text-sm font-medium leading-6 text-primaryBlack">
                Имя
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name="given-name"
                  id="given-name"
                  autoComplete="given-name"
                  placeholder="Введите текст"
                  disabled={true}
                  value={user.first_name}
                  className="block w-full rounded-md border-0 px-[16px] py-[8px] text-primaryBlack placeholder-text-[#DBDBDB]shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
          </div>
          <div>
          </div>
          <h3 className="mt-16 text-16 sm:text-24 font-rubikSemi text-primaryBlack">
            Контакты
          </h3>
          <div className="pb-12">
            <div className="w-full max-w-full mt-10 grid grid-cols-2 gap-x-12 gap-y-8 sm:grid-cols-9">
              <div className="col-span-2 sm:col-span-3">
                <label htmlFor="last-name" className="block text-sm font-medium leading-6 text-primaryBlack">
                  Номер телефона
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="last-name"
                    id="last-name"
                    autoComplete="family-name"
                    placeholder="Введите текст"
                    disabled={true}
                    value={user.phone}
                    className="block w-full rounded-md border-0 px-[16px] py-[8px] text-primaryBlack placeholder-text-[#DBDBDB] shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              <div className="col-span-2 sm:col-span-3">
                <label htmlFor="given-name" className="block text-sm font-medium leading-6 text-primaryBlack">
                  Email
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="given-name"
                    id="given-name"
                    autoComplete="given-name"
                    placeholder="Введите текст"
                    disabled={true}
                    value={user.email}
                    className="block w-full rounded-md border-0 px-[16px] py-[8px] text-primaryBlack placeholder-text-[#DBDBDB]shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
            </div>
            <div>
            </div>
          </div>
          <div className="inline-flex items-center gap-x-4 mt-2">
            <button
              onClick={() => {
                navigate('/account/edit');
              }}
              className="flex justify-center rounded-md bg-accentBlue px-[16px] py-[8px] text-16 leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
            >
              Изменить данные
            </button>
            <button
              onClick={async () => {
                await logout();
              }}
              className="flex justify-center rounded-md bg-accentGreen px-[16px] py-[8px] text-16 leading-6 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
            >
              Выйти
            </button>
          </div>
          <h2 className="my-10 text-24 sm:text-36 font-rubikSemi text-primaryBlack">
            Мои бронирования
          </h2>
          <div className="grid grid-cols-1 gap-y-4 sm:grid-cols-2 sm:gap-x-6 sm:gap-y-10 lg:grid-cols-3 lg:gap-x-8">
            {tourMini && tourMini.map((tour: any) => (
              <TourCard
                id={tour.id}
                region={tour.region}
                imageSrc={tour.photo_mini || `${process.env.PUBLIC_URL}/tambov.png`}
                name={tour.name}
                description={tour.description}
                href={`/tours/${tour.nominal_name}`}
                bookingLabel='Редактировать'
                trip_date={tour.trip_date}
                count={tour.count}
              />
            ))}
          </div>
          <div className="mt-10">
            <button
              onClick={() => {
                navigate('/tours');
              }}
              className="flex justify-center rounded-md bg-accentBlue px-[16px] py-[8px] text-16 leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
            >
              Посмотреть другие туры
            </button>
          </div>
        </div>
      </div>
      <div className="pointer-events-none fixed top-0 right-0 w-80 px-4 py-6 z-50">
        {
          Boolean(error.length) ?
            <Notification text={error} setText={setError} type="error" /> : Boolean(message.length) ?
            <Notification text={message} setText={setMessage} type="message" /> : null
        }
      </div>
    </div>
  );
};
