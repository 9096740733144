import {useParams} from "react-router-dom";
import NotFound from "../pages/NotFound";
import Title from "../components/Title";
import SectionHeader from "../components/SectionHeader";
import CardList from "../components/PaginateList";
import Reminder from "../components/Reminder";
import Feedback from "../components/Feedback";
import {useContext, useEffect, useRef, useState} from "react";
import {TourContext} from "../context";
import RegionFacts from "../components/RegionFacts";
import {getRegions} from "../actions";
import {Registry} from "../components/RegionsMap";

export default function Region() {
  const {regions} = useContext(TourContext);
  const {regionId} = useParams();
  const regionMapRef = useRef<null|HTMLDivElement>(null);
  const [facts, setFacts] = useState([] as string[]);
  const [registry, setRegistry] = useState([] as Registry[]);

  useEffect(() => {
    const getRegionInfo = async () => {
      try {
        const result = await getRegions(regionId!);
        setFacts([result["Fact 0"].fact, result["Fact 1"].fact, result["Fact 2"].fact]);
        setRegistry(Object.values(result["About_places"]));
      } catch (e) {
        console.log((e as Error).message);
      }
    }

    getRegionInfo()
  }, [regionId]);

  if (!regions.map((r) => r.id).includes(regionId!)) {
    return <NotFound />
  }

  const title = regions.find((r) => r.id === regionId)!.name;
  const mapId = regions.find((r) => r.id === regionId)!.mapId ? regions.find((r) => r.id === regionId)!.mapId : "https://yandex.ru/map-widget/v1/?um=constructor%3A8c8397539bed3bfa70ed83eb553789e683c0c28b77c5590da8f6e803b33560fc&amp;source=constructor";
  const mapSubHeader = regions.find((r) => r.id === regionId)!.mapSubHeader;
  const registrySubHeader = regions.find((r) => r.id === regionId)!.registrySubHeader;
  // const facts = regions.find((r) => r.id === regionId)!.facts || [""];
  // const registry = regions.find((r) => r.id === regionId)!.registry || [];

  return (
    <div>
      <div className="bg-bgLight min-h-screen">
        <div className="relative isolate overflow-hidden pt-80 min-h-[50vh]">
          <div className="mx-auto max-w-full mt-[-12rem]">
            <div className="text-center">
              <Title title={title} />
              <div className="mt-4 flex items-center justify-center gap-x-6">
                <button
                  onClick={() => {
                    regionMapRef.current!.scrollIntoView({ behavior: 'smooth', block: 'start' });
                  }}
                  className="rounded-md bg-accentGreen px-6 py-2.5 text-16 font-normal text-primaryBlack shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                >
                  Посмотреть карту
                </button>
              </div>
              <RegionFacts facts={facts} />
            </div>
          </div>
        </div>
        <div className="mt-10" ref={regionMapRef}>
          <SectionHeader title={mapSubHeader} />
          <iframe
            title={regionId}
            src={mapId}
            width="100%" height="400"></iframe>
          <SectionHeader title={registrySubHeader} />
          {Boolean(registry.length) ?
            <CardList registry={registry} /> :
            <div className="flex items-center justify-center mx-auto my-0 sm:my-8">
              <img src={`${process.env.PUBLIC_URL}/registry_soon.svg`} alt="" />
            </div>
          }
          <Reminder />
          <Feedback theme="light" />
        </div>
      </div>
    </div>
  );
};
