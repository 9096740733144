import React, { createContext, useContext, useState, useEffect, ReactNode, useCallback } from "react";
import { getProfile, getRefreshToken } from "./actions";
import { Profile } from "./pages/Account";

// Define the types for your context
interface AuthContextType {
  isLogged: boolean;
  setLogged: (isLogged: boolean) => void;
  loading: boolean;
  user: Profile;
  setUser: (u: Profile) => void;
}

// Create the context with default values
const AuthContext = createContext<AuthContextType | undefined>(undefined);

// Export a custom hook to use the AuthContext
export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [isLogged, setLogged] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [user, setUser] = useState<Profile>({} as Profile);

  // Use useCallback to memoize the function and avoid unnecessary re-renders
  const checkLoginStatus = useCallback(async () => {
    try {
      const accessToken = localStorage.getItem("access_token");
      if (!accessToken) {
        setLogged(false);
        setLoading(false);
        return;
      }

      const result = await getProfile(accessToken);

      if (result.message && result.message === "Failed to fetch!") {
        localStorage.removeItem("access_token");
        localStorage.removeItem("refresh_token");
        setLogged(false);
        return;
      }

      if (!result.status) {
        setLogged(true);
        setUser(result);
      } else {
        const refreshToken = localStorage.getItem("refresh_token");
        if (refreshToken) {
          const accessUpdate = await getRefreshToken(refreshToken);
          if (accessUpdate.access_token) {
            localStorage.setItem("access_token", accessUpdate.access_token);
            const newResult = await getProfile(accessUpdate.access_token);
            if (!newResult.status) {
              setUser(newResult);
              setLogged(true);
            } else {
              localStorage.removeItem("access_token");
              localStorage.removeItem("refresh_token");
              setLogged(false);
            }
          } else {
            localStorage.removeItem("access_token");
            localStorage.removeItem("refresh_token");
            setLogged(false);
          }
        } else {
          setLogged(false);
        }
      }
    } catch (e) {
      console.log((e as Error).message);
      setLogged(false);
    } finally {
      setLoading(false);
    }
  }, []);

  // Use useEffect with an empty dependency array to ensure it only runs once
  useEffect(() => {
    checkLoginStatus();
  }, [checkLoginStatus]);

  return (
    <AuthContext.Provider value={{ isLogged, setLogged, loading, user, setUser }}>
      {children}
    </AuthContext.Provider>
  );
};
