import {useNavigate} from "react-router-dom";
import {EnvelopeIcon} from "@heroicons/react/24/outline";
import {useState} from "react";
import {SubmitHandler, useForm} from "react-hook-form";
import {forgetPassword, User} from "../actions";
import Notification from "../components/Notification";

export default function ResetPassword() {
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
  } = useForm<User>()
  const onSubmit: SubmitHandler<User> = async (data) => {
    console.log('data: ', data);
    try {
      console.log(loading);
      setLoading(true);
      setError("");
      const result = await forgetPassword(data.email);
      setLoading(false);
      if (result.status === "success") {
        setMessage(result.message);
      } else {
        setError(result.message);
      }
    } catch (e) {
      console.log((e as Error).message);
      setError("Произошла непредвиденная ошибка! Попробуйте позже или свяжитесь с нами");
    }
  };

  return (
    <div className="h-full bg-bgDark min-h-screen">
      <div className="flex min-h-full flex-1 flex-col justify-center items-start px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <div onClick={() => navigate('/')} className="cursor-pointer -m-1.5 p-1.5">
            <span className="sr-only">SciTravel</span>
            <img
              className="mx-auto h-30 w-auto"
              src={`${process.env.PUBLIC_URL}/logo_white_icon.svg`}
              alt="SciTravel"
            />
          </div>
          <h2 className="mt-10 text-36 font-rubikSemi text-white">
            Сброс пароля
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-md">
          <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
            <div className="relative mt-2 rounded-md shadow-sm">
              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <EnvelopeIcon className="h-5 w-5 text-[#DBDBDB]" aria-hidden="true" />
              </div>
              <input
                id="email"
                type="email"
                autoComplete="email"
                placeholder="Введите email"
                {...register("email", {required: true})}
                className="block h-[40px] w-[270px] rounded-md border-0 bg-white pl-10 py-[8px] px-[16px] text-primaryBlack placeholder-[#A7A7A7] shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-accentBlue sm:text-sm sm:leading-6"
              />
            </div>
            <button
              type="submit"
              className="flex justify-center rounded-md bg-accentBlue px-[16px] py-[8px] text-16 leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
            >
              Отправить
            </button>
          </form>
        </div>
      </div>
      <div className="pointer-events-none fixed top-0 right-0 w-80 px-4 py-6 z-50">
        {
          Boolean(error.length) ?
            <Notification text={error} setText={setError} type="error" /> : Boolean(message.length) ?
            <Notification text={message} setText={setMessage} type="message" /> : null
        }
      </div>
    </div>
  )
}
