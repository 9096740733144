import {Bars3Icon, XMarkIcon} from "@heroicons/react/24/outline";
import { Dialog } from '@headlessui/react';
import {useContext, useState} from "react";
import {useNavigate, NavLink} from "react-router-dom";
import {TourContext} from "../context";
import Logo from "./Logo";
import {useAuth} from "../auth_context";

export default function Header() {
  const {mapRef, setShouldScrollToMap} = useContext(TourContext);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const navigate = useNavigate();
  const {isLogged} = useAuth();

  return (
    <header className="absolute inset-x-0 top-0 z-50">
      <nav className="flex items-center justify-between pt-0 pl-0 p-6 lg:px-8" aria-label="Global">
        <div className="flex lg:flex-1">
          <div
            onClick={() => {
              navigate('/');
            }}
            className="cursor-pointer -m-2.5 pl-0 pr-1.5 py-1.5"
          >
            <span className="sr-only">SciTravel</span>
            <Logo />
          </div>
        </div>
        <div className="flex lg:hidden">
          {!mobileMenuOpen && <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-end rounded-md p-2.5 text-primaryBlack"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Открыть меню</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>}
        </div>
        <div className="hidden lg:flex lg:justify-between lg:items-center gap-x-56">
          <div className="hidden lg:flex lg:justify-end lg:gap-x-16">
            <div
               onClick={() => {
                 if (window.location.pathname !== '/') {
                   setShouldScrollToMap(true);
                   navigate('/');
                 } else {
                   mapRef.current!.scrollIntoView({ behavior: 'smooth', block: 'start' });
                 }
               }}
               className="cursor-pointer text-16 font-normal leading-6"
            >
              Карта
            </div>
            <NavLink to='/tours' className="cursor-pointer text-16 font-normal leading-6">
              Туры
            </NavLink>
            <NavLink to='/team' className="cursor-pointer text-16 font-normal leading-6">
              Команда
            </NavLink>
          </div>
          {isLogged ?
            <div className="py-6">
              <NavLink
                className="rounded-md bg-accentBlue px-3 py-2 text-16 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                to='/account'
              >
                Личный кабинет
              </NavLink>
            </div> :
            <div className="py-6">
              <NavLink
                className="rounded-md bg-accentBlue px-3 py-2 text-16 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                to='/login'
              >
                Войти
              </NavLink>
            </div>
          }
        </div>
      </nav>
      <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
        <div className="fixed inset-0 z-50" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-bgDark/10">
          <div className="flex items-center justify-between">
            <div
              onClick={() => {
              navigate('/');
              setMobileMenuOpen(false);
              }}
              className="cursor-pointer -m-1.5 p-1.5"
            >
              <span className="sr-only">SciTravel</span>
              <Logo />
            </div>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-bgDark"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Закрыть меню</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-bgDark/25">
              <div className="space-y-2 py-6">
                <div
                  className="cursor-pointer -mx-3 block rounded-lg px-3 py-2 text-16 font-normal leading-7"
                  onClick={() => {
                    if (window.location.pathname !== '/') {
                      setShouldScrollToMap(true);
                      navigate('/');
                    } else {
                      mapRef.current!.scrollIntoView({ behavior: 'smooth', block: 'start' });
                    }
                    setMobileMenuOpen(false);
                  }}
                >
                  Карта
                </div>
                <NavLink to='/tours' onClick={() => setMobileMenuOpen(false)} className="cursor-pointer -mx-3 block rounded-lg px-3 py-2 text-16 font-normal leading-7">
                  Туры
                </NavLink>
                <NavLink to='/team' onClick={() => setMobileMenuOpen(false)} className="cursor-pointer -mx-3 block rounded-lg px-3 py-2 text-16 font-normal leading-7">
                  Команда
                </NavLink>
              </div>
              {isLogged ?
                <div className="py-6">
                  <NavLink
                    className="rounded-md bg-accentBlue px-3 py-2 text-12 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                    to='/account'
                    onClick={() => setMobileMenuOpen(false)}
                  >
                    Личный кабинет
                  </NavLink>
                </div> :
                <div className="py-6">
                  <NavLink
                    className="rounded-md bg-accentBlue px-3 py-2 text-12 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                    to='/login'
                    onClick={() => setMobileMenuOpen(false)}
                  >
                    Войти
                  </NavLink>
                </div>
              }
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  );
};
