import {BookData, LoginData} from "../common";
import {Order} from "./pages/BookEdit";

export type User = {
  first_name: string;
  last_name: string;
  phone: string;
  email: string;
  password: string;
  password2: string;
}

export type LoginUser = {
  email: string;
  password: string;
}

const apiUri = process.env["API_URI"] || "https://scitarvel-tampliervit.amvera.io";

export async function userRegister(data: User) {
  try {
    const resp = await fetch(`${apiUri}/registration`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    return resp.json();
  } catch (e) {
    console.log((e as Error).message);
  }
}

export async function completeRegistration(token: string) {
  try {
    const resp = await fetch(`${apiUri}/complete-registration/${token}`);

    return resp.json();
  } catch (e) {
    console.log((e as Error).message);
  }
}

export async function login(data: LoginData) {
  try {
    const resp = await fetch(`${apiUri}/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    return resp.json();

  } catch (e) {
    console.log((e as Error).message);
  }
}

export async function getRefreshToken(token: string) {
  try {
    const resp = await fetch(`${apiUri}/refresh-token/${token}`);

    return resp.json();
  } catch (e) {
    console.log((e as Error).message);
  }
}

export async function getRegions(regionId: string) {
  try {
    const resp = await fetch(`${apiUri}/regions/${regionId}`);

    return resp.json();
  } catch (e) {
    console.log((e as Error).message);
  }
}

export async function logOut(access_token: string) {
  try {
    const resp = await fetch(`${apiUri}/logout`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({access_token: access_token}),
    });

    return resp.json();

  } catch (e) {
    console.log((e as Error).message);
  }
}

export async function getTours() {
  try {
    const resp = await fetch(`${apiUri}/tours`);

    return resp.json();
  } catch (e) {
    console.log((e as Error).message);
  }
}

export async function getTourByName(tourId: string) {
  try {
    const resp = await fetch(`${apiUri}/tours/${tourId}`);

    return resp.json();
  } catch (e) {
    console.log((e as Error).message);
  }
}

export async function bookTour(tourId: string, bookData: BookData) {
  try {
    const resp = await fetch(`${apiUri}/book/${tourId}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(bookData),
    });

    return resp.json();
  } catch (e) {
    console.log((e as Error).message);
  }
}

export async function getOrderEdit(order_id: string, access_token: string): Promise<Order|undefined> {
  try {
    const resp = await fetch(`${apiUri}/order/${order_id}`, {
      method: "POST",
      body: JSON.stringify({"access_token": access_token}),
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
      }
    });

    return resp.json();
  } catch (e) {
    console.log((e as Error).message);
  }
}

export async function postOrderEdit(order_id: string, editData: any) {
  try {
    const resp = await fetch(`${apiUri}/order/edit/${order_id}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(editData),
    });

    return resp.json();
  } catch (e) {
    console.log((e as Error).message);
  }
}

export async function getProfile(access_token: string) {
  try {
    const resp = await fetch(`${apiUri}/profile`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({access_token: access_token}),
    });

    return resp.json();
  } catch (e) {
    console.log((e as Error).message);
    return e;
  }
}


export async function editProfile(access_token: string, data: User) {
  try {
    const resp = await fetch(`${apiUri}/profile/edit`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({access_token: access_token, ...data}),
    });

    return resp.json();
  } catch (e) {
    console.log((e as Error).message);
  }
}

export async function forgetPassword(email: string) {
  try {
    const resp = await fetch(`${apiUri}/forget-password`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({email: email}),
    });

    return resp.json();
  } catch (e) {
    console.log((e as Error).message);
  }
}

export async function resetPassword(token: string, password: string, password2: string) {
  try {
    const resp = await fetch(`${apiUri}/reset-password/${token}`, {
      method: "POST",
      headers:{
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        password: password,
        password2: password2,
      }),
    });

    return resp.json();
  } catch (e) {
    console.log((e as Error).message);
  }
}

export async function changePassword(token: string, password: string, password2: string) {
  try {
    const resp = await fetch(`${apiUri}/reset-password/${token}`, {
      method: "POST",
      headers:{
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        password: password,
        password2: password2,
      }),
    });

    return resp.json();
  } catch (e) {
    console.log((e as Error).message);
  }
}
