import Header from "./components/Header";
import Footer from "./components/Footer";
import {Outlet} from "react-router-dom";
import BookTourContext from "./context";

export default function Layout() {
  return (
    <div className="h-full">
      <BookTourContext>
        <Header />
        <Outlet />
        <Footer />
      </BookTourContext>
    </div>
  );
};
