import {useNavigate} from "react-router-dom";
import {useEffect} from "react";

export default function FirstTour() {
  const navigate = useNavigate();

  useEffect(() => {
    navigate('/tours/from_idea_to_discovery');
  }, [navigate]);
  return (
    <></>
  );
};
