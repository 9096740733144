import React from "react";
import {Registry} from "./RegionsMap";

export default function RegistryCard({type, adress, description, name, place_link, photo}: Registry) {

  return (
    <div
      key={place_link}
      className="group relative flex flex-col overflow-hidden shadow-lg bg-white"
    >
      <div className="bg-gray-200" dangerouslySetInnerHTML={{__html: photo}} />
      <div className="flex flex-1 flex-col space-y-4 p-4">
        <div>
          {type.includes("Музеи") ?
            <span className="inline-flex items-start rounded-md px-[12px] py-[4px] bg-[#6610F2] text-16 text-white">
              {type}
            </span> : type.includes("Заводы") ?
              <span className="inline-flex items-start rounded-md px-[12px] py-[4px] bg-[#0DCAF0] text-16 text-white">
              {type}
            </span> : type.includes("центры") ?
                <span className="inline-flex items-start rounded-md px-[12px] py-[4px] bg-[#0D6EFD] text-16 text-white">
              {type}
            </span> : type.includes("зоопарки") ? <span className="inline-flex items-start rounded-md px-[12px] py-[4px] bg-[#00D1B2] text-16 text-white">
              {type}
            </span> : type.includes("НИИ") ?
                  <span className="inline-flex items-start rounded-md px-[12px] py-[4px] bg-[#6F42C1] text-16 text-white">
              {type}
            </span> : null
          }
        </div>
        <h3 className="text-24 leading-[28.44px] font-rubikSemi text-slight">
          {name}
        </h3>
        <p className="text-16 text-slight">{description}</p>
        <p className="inline-flex items-center text-sm text-gray-600 mt-2">Адрес: {adress}</p>
        <a href={place_link} target="_blank" rel="noreferrer" className="inline-flex items-center text-sm text-accentBlue underline mt-2">Источник</a>
      </div>
    </div>
  );
};
