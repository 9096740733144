export default function NotFound() {
  return (
    <div className="bg-bgLight min-h-screen">
      <div className="flex flex-col items-center justify-center min-h-screen">
        <h1 className="mx-auto font-surfbars mt-[-3rem] text-64 lg:text-128 text-center text-accentBlue">
          404
        </h1>
        <h2 className="text-[#1F2B37] text-center text-20 sm:text-36 font-rubikSemi">Страница не найдена</h2>
      </div>
    </div>
  );
};