export default function SoonCard() {
  return (
    <div className="relative flex flex-col overflow-hidden shadow-lg bg-transparent h-full">
      <div className="absolute inset-0 w-full h-full bg-cover bg-center" style={{ backgroundImage: 'radial-gradient(circle at top, #77F2A1 0%, rgba(119, 242, 161, 0) 60%), radial-gradient(circle at right, #4F76F6 0%, rgba(79, 118, 246, 0) 70%)' }}></div>
      <div className="relative flex flex-col items-center justify-center h-full z-10 my-48 sm:my-0 text-bgDark">
        <span className="my-2 py-4 text-36 leading-[28.44px] font-rubikSemi">Тур – скоро!</span>
        <span className="text-16 leading-6 text-center mx-8">Мы активно работаем над тем, чтобы Вы отправились в незабываемое путешествие.</span>
      </div>
    </div>
  );
}
