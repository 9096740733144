import {useNavigate} from "react-router-dom";
import {useContext} from "react";
import {TourContext} from "../context";

export default function Hero() {
  const {mapRef, heroRef} = useContext(TourContext);
  const navigate = useNavigate();

  return (
    <div ref={heroRef} className="bg-bgLight min-h-screen">
      <div className="relative isolate overflow-hidden pt-80 min-h-screen">
        <div
          className="absolute hidden sm:block inset-0 -z-10 h-full w-full object-cover"
          style={{
            backgroundImage: `url("${process.env.PUBLIC_URL}/main.png")`,
            backgroundPosition: 'top',
            backgroundSize: 'cover',
          }}
          aria-hidden="true"
        ></div>
        {/*<div*/}
        {/*  className="absolute block sm:hidden inset-0 -z-10 h-full w-full object-cover"*/}
        {/*  style={{*/}
        {/*    backgroundImage: `url("${process.env.PUBLIC_URL}/390.png")`,*/}
        {/*    backgroundPosition: 'top',*/}
        {/*    backgroundSize: 'cover',*/}
        {/*  }}*/}
        {/*  aria-hidden="true"*/}
        {/*></div>*/}
        <div className="mx-auto max-w-full mt-[-12rem]">
          <div className="text-center">
            <h1 className="mx-auto font-surfbars mt-[-3rem] xl:leading-[100px] leading-snug text-48 lg:text-64 xl:text-96 text-accentBlue">
              Открывай мир знаний,<br/>открывая Россию!
            </h1>
            <p className="max-w-2xl mx-auto mt-6 text-16 text-pretty leading-6 text-main">
              Наш портал содержит <span className="text-accentBlue">карту</span> регионов Центрального федерального округа России<br/>с реестром доступных объектов научно-популярного туризма, а также раздел<br/>с <span className="text-accentBlue">тематическими турами</span> для покупки.
            </p>
            <div className="mt-10 flex items-center justify-center gap-x-6">
              <button
                onClick={() => mapRef.current!.scrollIntoView({ behavior: 'smooth', block: 'start' })}
                className="rounded-md bg-accentGreen px-6 py-2.5 text-16 font-normal text-primaryBlack shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
              >
                Изучить карту
              </button>
              <button
                type="button"
                onClick={() => navigate('/tours')}
                className="rounded-md bg-accentBlue px-6 py-2.5 text-16 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
              >
                Приобрести тур
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
