import {useEffect} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {completeRegistration} from "../actions";
import Spinner from "../components/Spinner";

export default function CompleteRegistration() {
  const { regToken } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const complete = async () => {
      try {
        console.log(regToken);
        const result = await completeRegistration(regToken!);
        console.log(result);
        navigate('/login', {state: {message: result.message, error: ""}});
      } catch (e) {
        console.log((e as Error).message);
      }
    }

    regToken && complete();
  }, [regToken, navigate]);

  return (
    <div className="bg-bgDark min-h-screen flex items-center justify-center my-24">
      <Spinner color="gray" size={10} />
    </div>
  );
}
