import React from "react";
import {useNavigate, useParams} from "react-router-dom";
import {TourFull} from "../pages/Tour";

export default function TourInfoCard({tour}: {tour: TourFull}) {
  const { tourId } = useParams();
  const navigate = useNavigate();

  return (
    <div className="p-4">
      <div className="inline-flex items-center gap-x-2 text-20"><img className="h-5" src={`${process.env.PUBLIC_URL}/time_icon.svg`} alt="Длительность" /> Длительность тура: <span className="text-accentBlue">{tour.lasting}</span></div>
      <div className="inline-flex items-center gap-x-2 text-20"><img className="h-5" src={`${process.env.PUBLIC_URL}/place_icon.svg`} alt="Точка сбора" /> Точка сбора: <span className="text-accentBlue">{tour.meeting_point}</span></div>
      <div className="p-6 my-5 ring-1 ring-gray-100 shadow-xl">
        <h3 className="inline-flex items-center gap-x-2 text-16 sm:text-24 font-rubikSemi"><img className="h-7" src={`${process.env.PUBLIC_URL}/calender_icon.svg`} alt="Даты заезда" /> Даты заезда</h3>
        <ul className="my-2">
          {tour.dates && tour.dates.split('/').map((date: any) => (
            <li>{date}</li>
          ))}
        </ul>
        <div className="mt-3">
          <button
            onClick={() => {
              navigate(`/book/${tourId}`);
            }}
            className="flex justify-center rounded-md bg-accentBlue px-[16px] py-[8px] text-16 leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
          >
            Забронировать тур
          </button>
        </div>
      </div>
      <h3 className="my-6 text-16 sm:text-24 font-rubikSemi text-primaryBlack inline-flex items-center gap-x-2">
        <img className="h-7" src={`${process.env.PUBLIC_URL}/wallet_icon.svg`} alt="Стоимость тура" /> Стоимость тура: <span className="text-accentBlue">{tour.value} руб./чел.</span>
      </h3>
    </div>
  );
};
