import RegionsMap from "./RegionsMap";
import RegionsList from "./RegionsList";
import {useContext} from "react";
import {TourContext} from "../context";

export default function RegionChoice() {
  const {mapRef} = useContext(TourContext);

  return (
    <div ref={mapRef} className="bg-bgLight py-6">
      <h3 className="max-w-5xl mx-auto font-rubikSemi text-24 text-center text-primaryBlack">
        Карта объектов научно-популярного туризма Центрального федерального округа
      </h3>
      <h4 className="hidden sm:block max-w-5xl mt-4 mx-auto text-16 text-center text-accentBlue">
        Наведите курсор на интересующий регион
      </h4>
      <h4 className="block sm:hidden max-w-5xl mt-4 mx-auto text-16 text-center text-accentBlue">
        Нажмите на интересующий регион
      </h4>
      <div className="hidden sm:flex mt-4 sm:mt-10 ml-0 sm:ml-16 items-center justify-between sm:justify-evenly">
        <RegionsMap />
        <RegionsList />
      </div>
      <div className="flex items-center justify-center sm:hidden">
        <RegionsList />
      </div>
    </div>
  );
};