export default function Partners() {
 return (
   <div className="bg-bgLight py-10 mx-auto text-center text-primaryBlack">
     <h2 className="py-4 font-rubikSemi text-24 sm:text-36">
       Партнеры
     </h2>
     <p className="pb-2 text-16 sm:text-20">
       Проект способствует развитию инициатив Десятилетия науки и технологий в России.
     </p>
     <div className="py-10 flex flex-col sm:flex-row gap-y-10 items-center justify-evenly">
         <img className="h-20 sm:h-full" src={`${process.env.PUBLIC_URL}/partner_1.svg`} alt=""/>
         <img className="h-20 sm:h-full" src={`${process.env.PUBLIC_URL}/partner_2.svg`} alt=""/>
         <img className="h-20 sm:h-full" src={`${process.env.PUBLIC_URL}/partner_3.svg`} alt=""/>
         <img className="h-20 sm:h-full" src={`${process.env.PUBLIC_URL}/partner_4.svg`} alt=""/>
     </div>
     <p className="mx-6 sm:mx-auto max-w-4xl py-4 text-pretty text-16 sm:text-20">
       <span className="text-accentBlue">Научно-популярный туризм</span> — это возможность погрузиться в синтез путешествия и науки, побывать в передовых лабораториях и на уникальных технологических объектах, лично познакомиться с людьми, которые совершили научные открытия.
     </p>
     <p className="mx-6 sm:mx-auto max-w-3xl py-4 text-16 sm:text-20">
       <a
         className="text-accentBlue underline"
         href="https://xn--80aa3ak5a.xn--p1ai/initiatives/nauchno-populyarnyy-turizm/"
         target="_blank"
         rel="noreferrer"
       >Подробнее</a> о научно-популярном туризме.
     </p>
   </div>
 );
};