import React from 'react';
import {Registry} from "./RegionsMap";
import RegistryCard from "./RegistryCard";


type Props = {
  registry: Registry[];
};

export default function CardList({registry}: Props) {
  return (
    <div className="mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8">
      <div className="grid grid-cols-1 gap-y-4 sm:grid-cols-2 sm:gap-x-6 sm:gap-y-10 lg:grid-cols-3 lg:gap-x-8">
        {registry.map((card) => (
          <RegistryCard
            name={card.name}
            photo={card.photo}
            description={card.description}
            adress={card.adress}
            type={card.type}
            place_link={card.place_link}
          />
        ))}
      </div>
    </div>
  );
};
