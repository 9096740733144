import {useNavigate} from "react-router-dom";
import {useContext} from "react";
import {TourContext} from "../context";

export default function RegionsList() {
  const {regions, setRegions} = useContext(TourContext);
  const navigate = useNavigate();

  const handleEnter = (id: string) => {
    const updatedRegions = regions.map(region => {
      return {
        ...region,
        isHovered: region.id === id,
        fill: region.id === id ? '#77F2A1' : '#4F76F6',
      };
    });
    setRegions(updatedRegions);
  };

  const handleLeave = (id: string) => {
    const updatedRegions = regions.map(region => {
      if (region.id === id) {
        return {
          ...region,
          isHovered: false,
          fill: '#4F76F6',
        };
      }
      return region;
    });
    setRegions(updatedRegions);
  };

  return (
    <ul className="py-4 sm:py-0">
      {regions.map((region) => (
        <li key={region.id}
          style={{
            color: region.isHovered ? '#4F76F6' : '#1F2B37',
            transition: 'color 0.2s',
          }}
          className="transition-colors duration-200 cursor-pointer text-center font-medium leading-9 text-bgDark text-24 sm:text-20 md:text-24"
          onMouseEnter={() => handleEnter(region.id)}
          onMouseLeave={() => handleLeave(region.id)}
          onTouchStart={() => handleEnter(region.id)}
          onTouchEnd={() => handleLeave(region.id)}
          onClick={() => navigate(`regions/${region.id}`)}
        >
          {region.name}
        </li>
      ))}
    </ul>
  );
};
