import React from "react";
import {NavLink, useNavigate} from "react-router-dom";

type Props = {
  id?: number;
  imageSrc: string;
  name: string;
  href?: string;
  region?: string;
  description: string;
  objectType?: string;
  address?: string;
  trip_date?: string;
  count?: number;
  nominal_name?: string;
  bookingLabel?: 'Забронировать тур' | 'Подробнее' | 'Редактировать';
};

export default function TourCard({id, imageSrc, name, href, region, description, objectType, address, bookingLabel, trip_date, count, nominal_name}: Props) {
  const navigate = useNavigate();
  return (
    <div
      key={id}
      className="group relative flex flex-col overflow-hidden shadow-lg bg-white"
    >
      <div className="bg-gray-200 group-hover:opacity-75">
        <NavLink to={href!}>
          <div dangerouslySetInnerHTML={{__html: imageSrc}}/>
        </NavLink>
      </div>
      <div className="flex flex-1 flex-col space-y-4 p-4">
        {objectType &&
          <div>
            <span className="inline-flex items-start rounded-md px-[12px] py-[4px] bg-[#00D1B2] text-16 text-white">
              {objectType}
            </span>
          </div>
        }
        <h3 className="text-24 leading-[28.44px] font-rubikSemi text-slight">
          <NavLink to={href!}>
            <span aria-hidden="true" className="absolute" />
            <span dangerouslySetInnerHTML={{__html:name}}></span>
          </NavLink>
        </h3>
        <div className="flex flex-1 flex-col justify-end">
          {bookingLabel !== "Редактировать" && <p className="text-16 text-slight">{region}</p>}
          {bookingLabel === "Редактировать" && <>
            <p className="text-16 text-slight">Дата: {trip_date}</p>
            <p className="text-16 text-slight">Количество человек: {count}</p>
            </>
          }
        </div>
        <p className="text-16 text-slight">{description}</p>
        {address && <p className="inline-flex items-center text-sm text-gray-600 mt-2">Адрес: {address}</p>}
        {bookingLabel?.length &&
          <div className="z-50 group py-3">
            <button
              type="button"
              className="z-50 rounded-md bg-accentBlue px-[16px] py-[8px] text-16 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-accentBlue"
              onClick={() => {
                bookingLabel === "Редактировать" ? navigate(`/book/edit/${id}`) : navigate(`/tours/${nominal_name}`);
              }}
            >
              {bookingLabel}
            </button>
          </div>
        }
      </div>
    </div>
  );
};
