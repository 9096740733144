import {useNavigate} from "react-router-dom";

export default function Reminder() {
  const navigate = useNavigate();

  return (
    <div className="bg-bgDark py-10">
      <h2 className="mx-auto text-center text-white font-rubikSemi text-20 sm:text-24 md:text-36">
        Не забудьте заглянуть в раздел с тематическими турами!
      </h2>
      <div className="mx-auto mt-10 text-center">
        <button
          type="button"
          onClick={() => navigate('/tours')}
          className="rounded-md bg-accentGreen px-5 py-2.5 text-16 text-primaryBlack shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
        >
          Выбрать тур
        </button>
      </div>
    </div>
  );
};