export default function WeWant() {
  return (
    <div className="bg-bgLight mx-auto text-center text-primaryBlack">
      <h2 className="py-4 font-rubikSemi text-24 md:text-36">
        Мы хотим,
      </h2>
      <div className="py-10 flex flex-col sm:flex-row items-center justify-evenly">
        <div className="flex flex-col justify-center items-center">
          <img src={`${process.env.PUBLIC_URL}/star.svg`} alt=""/>
          <div className="text-primaryBlack text-center text-16 py-2 mx-auto max-w-xs">
            чтобы научно-популярный туризм<br/>в России вышел в топ
          </div>
        </div>
        <div className="flex flex-col justify-center items-center">
          <img src={`${process.env.PUBLIC_URL}/test_tube.svg`} alt=""/>
          <div className="text-primaryBlack text-center py-2 mx-auto max-w-xs">
            чтобы о достижениях и перспективах российской науки знала вся страна
          </div>
        </div>
      </div>
    </div>
  );
};