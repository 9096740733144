import {useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {getOrderEdit, postOrderEdit} from "../actions";
import {SubmitHandler, useForm} from "react-hook-form";
import {useAuth} from "../auth_context";
import Notification from "../components/Notification";

type TourInfo = {
  additional_payment: string;
  dates: string;
  description: string;
  lasting: string;
  meeting_point: string;
  name: string;
  nominal_name: string;
  photo_general: string | null;
  program: string;
  region: string;
  value: number;
};

type OrderInfo = {
  count: number;
  created_on: string;
  email: string;
  first_name: string;
  last_name: string;
  phone: string;
  sex: string;
  trip_date: string;
  updated_on: string;
};

export type Order = {
  order_info: OrderInfo;
  tour_info: TourInfo;
}

export default function BookEdit() {
  const [agreed, setAgreed] = useState(false);
  const [order, setOrder] = useState<Order>({} as Order);
  const navigate = useNavigate();
  const { orderId } = useParams();
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const {loading, isLogged, user} = useAuth();

  const {
    register,
    handleSubmit,
    setValue,
  } = useForm<OrderInfo>()
  const onSubmit: SubmitHandler<OrderInfo> = async (data) => {
    console.log('data: ', data);
    try {
      if (agreed) {
        setError("");
        const result = await postOrderEdit(orderId!, {...data, tour_name: order.tour_info.nominal_name, access_token: localStorage.getItem("access_token")!});
        if (result.status === "success") {
          setMessage(result.message);
        }
      } else {
        setError("Необходимо подтвердить согласие на обработку данных");
      }
    } catch (e) {
      console.log((e as Error).message);
      setError("Произошла непредвиденная ошибка! Попробуйте позже или свяжитесь с нами");
    }
  };

  useEffect(() => {
    const getOrder = async () => {
      try {
        const result = await getOrderEdit(orderId!, localStorage.getItem("access_token")!);
        console.log(result);
        if (result) {
          setOrder(result);
          setValue("last_name", result.order_info.last_name);
          setValue("first_name", result.order_info.first_name);
          setValue("phone", result.order_info.phone);
          setValue("email", result.order_info.email);
          setValue("count", result.order_info.count);
        }
      } catch (e) {
        console.log((e as Error).message);
      }
    }

    if (loading) {
      console.log("Loading auth context");
    } else if (isLogged) {
      orderId ? getOrder() : navigate('/notfound');
    } else {
      !loading && navigate('/login');
    }
  }, [isLogged, loading, navigate, orderId, setValue, user]);

  console.log(order.tour_info && order.tour_info.dates && order.tour_info.dates.split('/'));
  return (
    <div className="h-full bg-bgLight min-h-screen">
      <div className="flex min-h-full flex-1 flex-col justify-start items-start px-8 py-12 lg:px-32">
        <h2 className="mt-16 text-24 sm:text-36 font-rubikSemi text-primaryBlack">
          Редактирование бронирования
        </h2>
        <form className="pb-12" onSubmit={handleSubmit(onSubmit)}>
          <div className="w-full max-w-full mt-10 grid grid-cols-2 gap-x-12 gap-y-8 sm:grid-cols-9">
            <div className="col-span-2 sm:col-span-3">
              <label htmlFor="last-name" className="block text-sm font-medium leading-6 text-primaryBlack">
                Дата тура
              </label>
              <div className="mt-2">
                <select
                  className="block w-full rounded-md border-0 px-[16px] py-[8px] mr-4 text-primaryBlack placeholder-text-[#DBDBDB] shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  {...register("trip_date", {required: true})}
                  defaultValue={order && order.order_info && order.order_info.trip_date}
                >
                  {order.tour_info && order.tour_info.dates && order.tour_info.dates.split('/').map((date) => (
                    <option>{date}</option>
                  ))}
                </select>
              </div>
            </div>

            <div className="col-span-2 sm:col-span-3">
              <label htmlFor="count" className="block text-sm font-medium leading-6 text-primaryBlack">
                Количество туристов
              </label>
              <div className="mt-2">
                <input
                  type="number"
                  id="count"
                  placeholder="Введите новый"
                  min={1}
                  max={50}
                  {...register("count", {required: true})}
                  className="block w-full rounded-md border-0 px-[16px] py-[8px] text-primaryBlack placeholder-text-[#DBDBDB]shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
          </div>
          <div>
          </div>
          <h3 className="mt-16 text-16 sm:text-24 font-rubikSemi text-primaryBlack">
            Контакты
          </h3>
          <div className="pb-12">
            <div className="w-full max-w-full mt-10 grid grid-cols-2 gap-x-12 gap-y-8 sm:grid-cols-9">
              <div className="col-span-2 sm:col-span-3">
                <label htmlFor="last-name" className="block text-sm font-medium leading-6 text-primaryBlack">
                  Фамилия
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    id="last-name"
                    autoComplete="family-name"
                    placeholder="Введите новый"
                    {...register("last_name", {required: true})}
                    className="block w-full rounded-md border-0 px-[16px] py-[8px] text-primaryBlack placeholder-text-[#DBDBDB] shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div className="col-span-2 sm:col-span-3">
                <label htmlFor="given-name" className="block text-sm font-medium leading-6 text-primaryBlack">
                  Имя
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    id="given-name"
                    autoComplete="given-name"
                    placeholder="Введите новый"
                    {...register("first_name", {required: true})}
                    className="block w-full rounded-md border-0 px-[16px] py-[8px] text-primaryBlack placeholder-text-[#DBDBDB]shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div className="col-span-2 sm:col-span-3">
                <label htmlFor="sex" className="block text-sm font-medium leading-6 text-primaryBlack">
                  Пол
                </label>
                <div className="mt-2">
                  <select
                    id="sex"
                    className="block w-full rounded-md border-0 px-[16px] py-[8px] text-primaryBlack placeholder-text-[#DBDBDB] shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    {...register("sex", {required: true})}
                  >
                    <option value="man">Мужской</option>
                    <option value="woman">Женский</option>
                  </select>
                </div>
              </div>

              <div className="col-span-2 sm:col-span-3">
                <label htmlFor="last-name" className="block text-sm font-medium leading-6 text-primaryBlack">
                  Номер телефона
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    id="last-name"
                    autoComplete="family-name"
                    placeholder="Введите новый"
                    {...register("phone", {required: true})}
                    className="block w-full rounded-md border-0 px-[16px] py-[8px] text-primaryBlack placeholder-text-[#DBDBDB] shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              <div className="col-span-2 sm:col-span-3">
                <label htmlFor="given-name" className="block text-sm font-medium leading-6 text-primaryBlack">
                  Email
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    id="given-name"
                    autoComplete="given-name"
                    placeholder="Введите новый"
                    {...register("email", {required: true})}
                    className="block w-full rounded-md border-0 px-[16px] py-[8px] text-primaryBlack placeholder-text-[#DBDBDB]shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
            </div>
            <div>
            </div>
          </div>
          <h2 className="my-6 text-16 sm:text-24 font-rubikSemi text-primaryBlack">
            Стоимость тура: {order.tour_info && order.tour_info.value} руб./чел.
          </h2>
          <div className="my-6 max-w-3xl mx-auto flex items-center">
            <input
              id="policy"
              name="policy"
              type="checkbox"
              checked={agreed}
              onChange={() => setAgreed(!agreed)}
              className="h-5 w-5 rounded border-bgDark/25 text-accentBlue focus:ring-accentBlue"
            />
            <div className="ml-3">
              <label className="text-12 text-primaryBlack">
                <span onClick={() => setAgreed(!agreed)} className="cursor-pointer">Я ознакомлен(а) с&nbsp;</span>
                <a href={`${process.env.PUBLIC_URL}/documents/policy.pdf`}
                   className="text-accentBlue underline"
                   target="_blank"
                   rel="noreferrer"
                >
                  политикой&nbsp;конфиденциальности
                </a>
                <span onClick={() => setAgreed(!agreed)} className="cursor-pointer">&nbsp;и согласен(а) на&nbsp;</span>
                <a
                  href={`${process.env.PUBLIC_URL}/documents/agreement.pdf`}
                  className="text-accentBlue underline"
                  target="_blank"
                  rel="noreferrer"
                >
                  обработку персональных данных
                </a>
              </label>
            </div>
          </div>
          <div className="mt-2">
            <button
              type="submit"
              className="flex justify-center rounded-md bg-accentBlue px-[16px] py-[8px] text-16 leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
            >
              Сохранить изменения
            </button>
          </div>
        </form>
      </div>
      <div className="pointer-events-none fixed top-0 right-0 w-80 px-4 py-6 z-50">
        {
          Boolean(error.length) ?
            <Notification text={error} setText={setError} type="error" /> : Boolean(message.length) ?
            <Notification text={message} setText={setMessage} type="message" /> : null
        }
      </div>
    </div>
  );
};
