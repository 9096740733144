type Props = {
  title: string;
};

export default function SectionHeader({title}: Props) {
  return (
    <div className="bg-bgDark py-10">
      <h2 className="mx-8 sm:mx-auto text-center font-rubikSemi text-pretty text-white text-20 sm:text-36">
        {title}
      </h2>
    </div>
  );
};