import {useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {SubmitHandler, useForm} from "react-hook-form";
import {editProfile, User} from "../actions";
import Notification from "../components/Notification";
import {useAuth} from "../auth_context";

export default function AccountEdit() {
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const {loading, isLogged, user} = useAuth();

  const {
    register,
    handleSubmit,
    setValue,
  } = useForm<User>({
  });

  useEffect(() => {
    if (loading) {
      console.log("Loading auth context");
    } else if (isLogged) {
      setValue("last_name", user.last_name);
      setValue("first_name", user.first_name);
      setValue("email", user.email);
      setValue("phone", user.phone);
    } else {
      !loading && navigate('/login');
    }
  }, [isLogged, loading, navigate, setValue, user]);

  const onSubmit: SubmitHandler<User> = async (data) => {
    console.log('data: ', data);
    try {
      setError("");
      const accessToken = localStorage.getItem("access_token");
      const result = await editProfile(accessToken!, data);
      if (result.status === "success") {
        navigate("/account", {state: {message: result.message, error: ""}});
      } else {
        setError(result.message);
      }
    } catch (e) {
      console.log((e as Error).message);
      setError("Произошла непредвиденная ошибка! Попробуйте позже или свяжитесь с нами");
    }
  };

  return (
    <div className="h-full bg-bgLight min-h-screen">
      <div className="flex min-h-full flex-1 flex-col justify-start items-start px-8 py-12 lg:px-32">
        <h2 className="mt-16 text-24 sm:text-36 text-center font-rubikSemi text-primaryBlack">
          Личный кабинет
        </h2>
        <form onSubmit={handleSubmit(onSubmit)} className="pb-12">
          <div className="w-full max-w-full mt-10 grid grid-cols-2 gap-x-12 gap-y-8 sm:grid-cols-9">
            <div className="col-span-2 sm:col-span-3">
              <label htmlFor="last-name" className="block text-sm font-medium leading-6 text-primaryBlack">
                Фамилия
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  id="last-name"
                  autoComplete="family-name"
                  placeholder="Введите текст"
                  {...register("last_name", {
                    required: true,
                  })}
                  className="block w-full rounded-md border-0 px-[16px] py-[8px] text-primaryBlack placeholder-text-[#DBDBDB] shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div className="col-span-2 sm:col-span-3">
              <label htmlFor="given-name" className="block text-sm font-medium leading-6 text-primaryBlack">
                Имя
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  id="given-name"
                  autoComplete="given-name"
                  placeholder="Введите текст"
                  {...register("first_name", {
                    required: true,
                  })}
                  className="block w-full rounded-md border-0 px-[16px] py-[8px] text-primaryBlack placeholder-text-[#DBDBDB]shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
          </div>
          <div>
          </div>
          <h3 className="mt-16 text-16 sm:text-24 font-rubikSemi text-primaryBlack">
            Контакты
          </h3>
          <div className="pb-12">
            <div className="w-full max-w-full mt-10 grid grid-cols-2 gap-x-12 gap-y-8 sm:grid-cols-9">
              <div className="col-span-2 sm:col-span-3">
                <label htmlFor="last-name" className="block text-sm font-medium leading-6 text-primaryBlack">
                  Номер телефона
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    id="phone"
                    autoComplete="tel"
                    placeholder="Введите текст"
                    {...register("phone", {
                      required: true,
                    })}
                    className="block w-full rounded-md border-0 px-[16px] py-[8px] text-primaryBlack placeholder-text-[#DBDBDB] shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              <div className="col-span-2 sm:col-span-3">
                <label htmlFor="given-name" className="block text-sm font-medium leading-6 text-primaryBlack">
                  Email
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    id="email"
                    autoComplete="email"
                    placeholder="Введите текст"
                    {...register("email", {
                      required: true,
                    })}
                    readOnly={true}
                    className="block w-full rounded-md border-0 px-[16px] py-[8px] text-primaryBlack placeholder-text-[#DBDBDB]shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
            </div>
            <div>
            </div>
          </div>
          <div className="mt-2">
            <button
              onClick={() => {
                navigate('/reset');
              }}
              className="flex justify-center rounded-md bg-accentBlue px-[16px] py-[8px] text-16 leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
            >
              Изменить пароль
            </button>
          </div>
          <div className="mt-10">
            <button
              type="submit"
              className="flex justify-center rounded-md bg-accentGreen px-[16px] py-[8px] text-16 leading-6 text-primaryBlack shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
            >
              Сохранить изменения
            </button>
          </div>
        </form>
      </div>
      <div className="pointer-events-none fixed top-0 right-0 w-80 px-4 py-6 z-50">
        {
          Boolean(error.length) ?
            <Notification text={error} setText={setError} type="error" /> : Boolean(message.length) ?
            <Notification text={message} setText={setMessage} type="message" /> : null
        }
      </div>
    </div>
  );
};
