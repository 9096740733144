import React from "react";

type Props = {
  facts: string[];
};

export default function RegionFacts({facts}: Props) {
  return (
    <div className="mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8">
      <div className="grid grid-cols-1 gap-y-4 sm:grid-cols-2 sm:gap-x-6 sm:gap-y-10 lg:grid-cols-3 lg:gap-x-8">
        {facts.map((fact, id) => (
          <div key={id} className="max-w-md ring-2 ring-gray-100 ring-offset-1 ring-offset-gray-50 shadow-md sm:shadow-xl">
            <div className="mx-auto px-8 flex flex-col items-center my-4 gap-6">
              <img
                src={`${process.env.PUBLIC_URL}/number_${id+1}_outline.svg`}
                alt=""
                className="h-14 w-14 mb-1"
              />
              <p className="text-16 text-pretty">
                {fact}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};