import Hero from "../components/Hero";
import Motto from "../components/Motto";
import Reminder from "../components/Reminder";
import Partners from "../components/Partners";
import WeWant from "../components/WeWant";
import Feedback from "../components/Feedback";
import RegionChoice from "../components/RegionChoice";
import {useContext, useEffect} from "react";
import {TourContext} from "../context";

function Home() {
  const {mapRef, shouldScrollToMap, setShouldScrollToMap} = useContext(TourContext);

  useEffect(() => {
    if (shouldScrollToMap) {
      mapRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
      setShouldScrollToMap(false);
    }
  }, [shouldScrollToMap, mapRef, setShouldScrollToMap]);

  return (
    <div>
      <Hero />
      <Motto />
      <RegionChoice />
      <Reminder />
      <WeWant />
      <Feedback theme="dark" />
      <Partners />
    </div>
  );
}

export default Home;
