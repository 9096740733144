import classNames from "classnames";
import {NavLink} from "react-router-dom";

type Props = {
  theme: 'light' | 'dark';
  hideTeam?: boolean;
};

export default function Feedback({theme, hideTeam}: Props) {
  return (
    <div className={classNames(theme === 'light' ? 'bg-white' : 'bg-bgDark', 'py-10')}>
      <h3 className={classNames(theme === 'light' ? 'test-[#1F2B37]' :  'text-white', 'max-w-5xl mx-6 sm:mx-auto font-rubikSemi text-20 sm:text-24 text-center')}>
        Вы также можете познакомиться с нашей командой и поддержать проект.<br/>Будем рады Вашей обратной связи!
      </h3>
      <div className="mt-10 flex items-center justify-center gap-x-6">
        {!hideTeam &&
            <NavLink
              to="/team"
              className="rounded-md bg-accentGreen px-3.5 py-2.5 text-16 font-normal text-primaryBlack shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
            >
            Узнать о команде
          </NavLink>
        }
        <a
          href="https://www.tinkoff.ru/cf/8CydJ6FDcNv"
          target="_blank"
          rel="noreferrer"
          className="rounded-md bg-accentBlue px-3.5 py-2.5 text-16 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
        >
          Поддержать проект
        </a>
      </div>
    </div>
  );
};