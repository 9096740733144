import {useNavigate} from "react-router-dom";
import {useContext} from "react";
import {TourContext} from "../context";

export type RegionChoiceProps = {
  regions: RegionProps[];
  setRegions: (regions: RegionProps[]) => void;
};

export type Registry = {
  type: string;
  adress: string;
  description: string;
  name: string;
  photo: string;
  place_link: string;
}

export type RegionProps = {
  id: string;
  name: string;
  d: string;
  fill: string;
  transform: string;
  isHovered: boolean;
  mapId: string;
  mapSubHeader: string;
  registrySubHeader: string;
  facts: string[];
  registry?: Registry[];
};

export default function RegionsMap() {
  const {regions, setRegions} = useContext(TourContext);
  const navigate = useNavigate();

  const handleEnter = (id: string) => {
    const updatedRegions = regions.map(region => {
      return {
        ...region,
        isHovered: region.id === id,
        fill: region.id === id ? '#77F2A1' : '#4F76F6',
      };
    });
    setRegions(updatedRegions);
  };

  const handleLeave = (id: string) => {
    const updatedRegions = regions.map(region => {
      if (region.id === id) {
        return {
          ...region,
          isHovered: false,
          fill: '#4F76F6',
        };
      }
      return region;
    });
    setRegions(updatedRegions);
  };

  return (
    <svg className="h-70 h-[40rem]" viewBox="0 0 572.39 469.93" fill="none" xmlns="http://www.w3.org/2000/svg">
      {regions.map((region) => (
        <path
          d={region.d}
          fill={region.fill}
          transform={region.transform}
          onMouseEnter={() => handleEnter(region.id)}
          onMouseLeave={() => handleLeave(region.id)}
          onTouchStart={() => handleEnter(region.id)}
          onTouchEnd={() => handleLeave(region.id)}
          onClick={() => navigate(`regions/${region.id}`)}
          className="cursor-pointer transition-colors duration-200"
        />
      ))}
    </svg>
  );
};
