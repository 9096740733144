import React, {useEffect, useState} from "react";
import TourInfoCard from "../components/TourInfoCard";
import TourProgram from "../components/TourProgram";
import {getTourByName} from "../actions";
import {useNavigate, useParams} from "react-router-dom";

export type TourFull = {
  activities_in_payment: string;
  additional_payment: string;
  dates: string;
  description: string;
  id: number;
  name: string;
  nominal_name: string;
  photo_general: string | null;
  program: string;
  value: number;
  meeting_point: string;
  lasting: string;
}

export default function Tour() {
  const {tourId} = useParams();
  const navigate = useNavigate();
  const [tour, setTour] = useState({} as TourFull);

  useEffect(() => {
    const tourInfo = async () => {
      try {
        const result = await getTourByName(tourId!);
        setTour(result);
      } catch (e) {
        console.log((e as Error).message);
      }
    }
    console.log(tourId);
    tourId ? tourInfo() : navigate('/notfound');
  }, [navigate, tourId]);

  return (
    <div className="bg-white min-h-screen">
      <div className="relative isolate overflow-hidden min-h-screen">
        <div
          className="absolute inset-0 -z-10 h-full w-full object-cover"
          style={{
            backgroundImage: `url("${process.env.PUBLIC_URL}/tambov_hero.svg")`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
          }}
          aria-hidden="true"
        ></div>
        <div className="absolute bottom-0 w-full">
          <div className="hidden sm:block bg-bgDark py-10">
            <h2 className="mx-8 sm:mx-auto text-center font-rubikSemi text-pretty text-white text-20 sm:text-36">
              {tour.name}
            </h2>
          </div>
          <div className="block sm:hidden block bg-bgDark py-8">
            <h2 className="mx-8 sm:mx-auto text-center font-rubikSemi text-pretty text-white text-20 sm:text-36">
              {tour.name}
            </h2>
          </div>
        </div>
      </div>
      <div className="flex flex-col-reverse items-baseline sm:flex-row sm:justify-evenly mx-auto my-3 mb-32">
        <div className="max-w-4xl px-4 mx-auto sm:ml-16 sm:mr-auto">
          <TourProgram tour={tour} />
        </div>
        <div className="max-w-2xl sm:mr-16 sm:ml-auto">
          <TourInfoCard tour={tour} />
        </div>
      </div>
    </div>
  );
};
