import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Layout from "./Layout";
import Home from './pages/Home';
import Region from "./pages/Region";
import FeaturedTours from "./pages/FeaturedTours";
import NotFound from "./pages/NotFound";
import ScrollToTop from "./components/ScrollToTop";
import Tour from "./pages/Tour";
import RemoveTrailingSlash from "./components/RemoveTrailSlash";
import Account from "./pages/Account";
import AccountEdit from "./pages/AccountEdit";
import Register from "./pages/Register";
import CompleteRegistration from "./pages/CompleteRegistration";
import Login from "./pages/Login";
import ResetPassword from "./pages/ResetPassword";
import ResetPassword2 from "./pages/ResetPassword2";
import Book from "./pages/Book";
import {AuthProvider} from "./auth_context";
import BookEdit from "./pages/BookEdit";
import FirstTour from "./pages/FirstTour";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <div className="h-full">
      <BrowserRouter>
        <AuthProvider>
          <ScrollToTop />
          <RemoveTrailingSlash />
          <Routes>
            <Route element={<Layout />}>
              <Route path="/" element={<Home />} />
              <Route path="/tours" element={<FeaturedTours />} />
              <Route path="/tours/1" element={<FirstTour />} />
              <Route path="/tours/:tourId" element={<Tour />} />
              <Route path="/book/:tourId" element={<Book />} />
              <Route path="/book/edit/:orderId" element={<BookEdit />} />
              <Route path="/regions/:regionId" element={<Region />} />
              <Route path="/account" element={<Account />} />
              <Route path="/account/edit" element={<AccountEdit />} />
              {/*<Route path="/team" element={<Team />} />*/}
              <Route path="*" element={<NotFound />} />
            </Route>
            <Route path="/register" element={<Register />} />
            <Route path="/complete-registration/:regToken" element={<CompleteRegistration />} />
            <Route path="/login" element={<Login />} />
            <Route path="/reset" element={<ResetPassword />} />
            <Route path="/reset-password/:passwordToken" element={<ResetPassword2 />} />
          </Routes>
        </AuthProvider>
      </BrowserRouter>
    </div>
  </React.StrictMode>
);

reportWebVitals();
