import React, {useEffect, useState} from "react";
import TourCard from "./TourCard";
import SoonCard from "./SoonCard";
import {getTours} from "../actions";

export type TourMini = {
  id: number;
  description_mini: string;
  name: string;
  nominal_name: string;
  photo_mini: string | null;
  region?: string;
}

export default function FeaturedList() {
  const [tours, setTours] = useState([] as TourMini[]);

  useEffect(() => {
    const getToursMini = async () => {
      try {
        const result = await getTours();
        console.log(Object.values(result));
        setTours(Object.values(result));
      } catch (e) {
        console.log((e as Error).message);
      }
    }

    getToursMini()
  }, []);

  return (
    <div className="bg-bgLight">
      <div className="mx-auto max-w-3xl px-4 py-12 sm:px-6 sm:py-20 lg:max-w-full lg:px-16">
        <h2 className="sr-only">Тематические туры</h2>

        <div className="grid grid-cols-1 gap-y-4 sm:grid-cols-2 sm:gap-x-6 sm:gap-y-10 lg:grid-cols-3 lg:gap-x-8">
          {Boolean(tours?.length) && tours.map((tour) => (
            <TourCard
              key={tour.id}
              id={tour.id}
              imageSrc={tour.photo_mini || `${process.env.PUBLIC_URL}/tambov.png`}
              name={tour.name}
              region={tour.region}
              description={tour.description_mini}
              href={`/tours/${tour.nominal_name}`}
              bookingLabel="Забронировать тур"
              nominal_name={tour.nominal_name}
            />
          ))}
          <SoonCard key={"soon1"} />
          <SoonCard key={"soon2"} />
        </div>
      </div>
    </div>
  );
};
