import {NavLink, useNavigate} from "react-router-dom";
import {useContext} from "react";
import {TourContext} from "../context";

const navigation = {
  documents: [
    { name: 'Согласие на обработку персональных данных', href: `${process.env.PUBLIC_URL}/documents/agreement.pdf` },
    { name: 'Политика конфиденциальности', href: `${process.env.PUBLIC_URL}/documents/policy.pdf` },
  ],
};

export default function Footer() {
  const {setShouldScrollToMap, setShouldScrollToHero, mapRef, heroRef} = useContext(TourContext);
  const navigate = useNavigate();

  return (
    <footer className="bg-bgDark" aria-labelledby="footer-heading">
      <div className="mx-auto max-w-full px-6 pb-8 pt-8 sm:pt-12 lg:px-8 lg:pt-16">
        <div className="xl:grid xl:grid-cols-12 xl:gap-8">
          <div className="space-y-8 col-span-4">
            <div
              onClick={() => {
                if (window.location.pathname !== '/') {
                  setShouldScrollToHero(true);
                  navigate('/');
                } else {
                  heroRef.current!.scrollIntoView({behavior: 'smooth', block: 'start'});
                }
              }}
              className="cursor-pointer"
            >
              <img
                src={`${process.env.PUBLIC_URL}/logo_white_icon.svg`}
                alt="SciTravel"
              />
            </div>
          </div>
          <div className="mt-16 gap-8 xl:col-span-8 xl:mt-0">
            <div className="md:grid md:grid-cols-3 md:gap-8">
              <div className="mt-10 md:mt-0 sm:ml-6">
                <h3 className="font-rubikSemi text-24 leading-6 text-white">Навигация</h3>
                <ul className="mt-6 space-y-4">
                  <li>
                    <div
                      onClick={() => {
                        if (window.location.pathname !== '/') {
                          setShouldScrollToMap(true);
                          navigate('/');
                        } else {
                          mapRef.current!.scrollIntoView({behavior: 'smooth', block: 'start'});
                        }
                      }}
                      className="cursor-pointer font-normal text-16 leading-6 text-gray-300 text-white"
                    >
                      Карта
                    </div>
                  </li>
                  <li>
                    <NavLink to='/tours' className="font-normal text-16 leading-6 text-gray-300 text-white">
                      Туры
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to='/team' className="font-normal text-16 leading-6 text-gray-300 text-white">
                      Команда
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to='/account' className="font-normal text-16 leading-6 text-gray-300 text-white">
                      Личный кабинет
                    </NavLink>
                  </li>
                </ul>
              </div>
              <div className="mt-10 md:mt-0">
                <h3 className="font-rubikSemi text-24 leading-6 text-white">Документы</h3>
                <ul className="mt-6 space-y-4">
                  <li className="font-normal text-16 leading-6 text-gray-300 text-white">
                    ООО «САЙТРЕВЛ»
                  </li>
                  <li className="text-12 leading-4 text-gray-300 text-white">
                    ИНН 9200018989<br/>
                    ОГРН 1239200004669
                  </li>
                  <li className="text-12 leading-4 text-gray-300 text-white">
                    Мы в реестре туроператоров: РТО 026198
                  </li>
                  {navigation.documents.map((item) => (
                    <li key={item.name}>
                      <a href={item.href} target="_blank" rel="noreferrer noopener"
                         className="font-normal text-16 leading-6 text-gray-300 text-white">
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="mt-10 md:mt-0">
                <h3 className="font-rubikSemi text-24 leading-6 text-white">Контакты</h3>
                <ul className="mt-6 space-y-4">
                  <li key="email">
                    <a href="mailto:scietravel@gmail.com"
                       className="text-16 underline leading-6 text-gray-300 text-white">
                      scietravel@gmail.com
                    </a>
                  </li>
                  <li key="socials">
                    <div className="flex items-center justify-start gap-x-6">
                      <a href="https://vk.com/scitravel" target="_blank" rel="noreferrer"><img src={`${process.env.PUBLIC_URL}/logo_vk.svg`} alt="VK"/></a>
                      <a href="https://t.me/scitravel" target="_blank" rel="noreferrer"><img src={`${process.env.PUBLIC_URL}/logo_tg.svg`} alt="Telegram"/></a>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-4 border-t border-white/10 pt-8 sm:mt-20 lg:mt-24">
          <p className="font-normal text-12 leading-6 text-white">&copy; {new Date().getFullYear()} SciTravel. Все права
            защищены.</p>
        </div>
      </div>
    </footer>
  );
};
